.App {
  text-align: center;
  overflow-x: hidden;
position: relative;
}

.bg {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
}

.App-logo {
  width: 100vw;
  z-index: 1;
  max-width: 1200px;
  margin-top: -900px;
  margin-bottom: 5vh;


}

.headerimg {
  width: 100%;
  max-width: 500px;
float: right;
position: relative;
left: 40px;
margin-bottom: -100px;
}

.listpod {
  box-sizing: border-box;
  padding: 20px;
  background-color: #222;
  border-radius: 20px;
  margin-top: 30px;
  margin-bottom: 80px;
}

.listpod a {
 color: #00FFA3;
 text-decoration: none;
}

.listpod p {
  color: #ccc;
}

.listpod a:hover {
  text-decoration: underline;
}

.listpod li {
  margin-bottom: 10px;
}

.redeem {
  text-align: left;
  margin-top: 20px;
  
}

.redeemtext {
  color: white;
}

.redeemtext p {
  max-width: none;
  margin-bottom: 20px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 200s linear;
  }
}

.App-header {
  color: white;
  margin: 0 auto;
  z-index: 2;
  position: relative;
  text-align: center;
  box-sizing: border-box;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;



}

.glare-wrapper:hover {
  cursor: pointer;

}

.glare-wrapper {
  mask-image: url(components/CardGrid/masks/1.svg) !important;
  mask-repeat: no-repeat;
  mask-size: contain;
}

#ii .glare-wrapper{
  mask-image: url(components/CardGrid/masks/2.svg) !important;
  mask-repeat: no-repeat;
  mask-size: contain;
}
#iii .glare-wrapper{
  mask-image: url(components/CardGrid/masks/3.svg) !important;
  mask-repeat: no-repeat;
  mask-size: contain;
}

#iv .glare-wrapper{
  mask-image: url(components/CardGrid/masks/4.svg) !important;
  mask-repeat: no-repeat;
  mask-size: contain;
}
#v .glare-wrapper{
  mask-image: url(components/CardGrid/masks/5.svg) !important;
  mask-repeat: no-repeat;
  mask-size: contain;
}
#vi .glare-wrapper{
  mask-image: url(components/CardGrid/masks/6.svg) !important;
  mask-repeat: no-repeat;
  mask-size: contain;
}
#vii .glare-wrapper{
  mask-image: url(components/CardGrid/masks/7.svg) !important;
  mask-repeat: no-repeat;
  mask-size: contain;
}
#viii .glare-wrapper{
  mask-image: url(components/CardGrid/masks/8.svg) !important;
  mask-repeat: no-repeat;
  mask-size: contain;
}

#ix .glare-wrapper{
  mask-image: url(components/CardGrid/masks/9.svg) !important;
  mask-repeat: no-repeat;
  mask-size: contain;
}
#x .glare-wrapper{
  mask-image: url(components/CardGrid/masks/10.svg) !important;
  mask-repeat: no-repeat;
  mask-size: 100%;
}
#xi .glare-wrapper{
  mask-image: url(components/CardGrid/masks/11.svg) !important;
  mask-repeat: no-repeat;
  mask-size: contain;
}
#xii .glare-wrapper{
  mask-image: url(components/CardGrid/masks/12.svg) !important;
  mask-repeat: no-repeat;
  mask-size: contain;
}

#x .glare {

background: linear-gradient(180deg, #E6533F 2.72%, #FFCF24 33.88%, #26BF9A 75.12%, #7D35F2 100%) !important;

}

.Zodiac-Text {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 700px;
}
.poweredby {
  margin-top: 20px;
  margin-bottom: 100px;
  width: 100%;
  max-width: 500px;

}


.flexgrid {
  display: flex;
  width: 100%;
  justify-content: center;
  max-width: 1140px;
  margin: 0 auto;
  flex-wrap: wrap;
  margin-bottom: 200px;


}

.card:hover {
  cursor: pointer;
}

.card {
width: 100%;}

.flexgrid > div {
  box-sizing: border-box;
  padding: 20px;
  flex-grow: 0.5;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@media only screen and (max-width: 1200px) {
  .headerimg {
    margin-bottom: 0px;
    max-width: 400px;
    }

}

@media only screen and (max-width: 991px) {
  .App-logo {
    margin-top: -600px;

  }

  .headerimg {
    margin-bottom: 0px;
    max-width: 500px;
    }
}


@media only screen and (max-width: 768px) {

  .App-logo {
    margin-top: -450px;

  }

  .headerimg {
  margin-bottom: 0px;
  max-width: 400px;
  }
  

  .bg {
    display: none;
  }
}