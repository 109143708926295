
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600&display=swap');

body {
  margin: 0;
  background-color: #000000;
  text-align: center;
  position: relative;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  overflow-x: hidden; /* Hide scrollbars */

}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


h1 {
  color: white;
  font-size: 160px;
    font-weight: 600;
    margin: 0 auto;
    position: relative;
    letter-spacing: -0.05em;
    margin-bottom: 0px;
    max-width: 1100px;
    line-height: 0.9;
  }

h2 {
  color: white;
  font-size: 29px;
    font-weight: 500;
    margin: 0 auto;
    position: relative;
    margin-bottom: 30px;

}

h4 {
  color: rgb(114, 114, 114);
  font-size: 50px;
    font-weight: 600;
    margin: 0 auto;
    position: relative;
    margin-bottom: 60px;
    letter-spacing: -0.05em;
    line-height: 1;
    background-image: linear-gradient(90deg, #00FFA3, #3B7EDD, #DC1FFF);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;

}

h3 {
  letter-spacing: 10px;
  font-size: 25px;
  background-color: #f3ec78;
    background-image: linear-gradient(90deg, #00FFA3, #3B7EDD, #DC1FFF);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    display: inline-block;
}

p {
  font-size: 20px;
  opacity: 0.5;
  font-weight: 300;
  margin: 0 auto;
  width: 100%;
  max-width: 550px;
  margin-bottom: 50px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media only screen and (max-width: 991px) {
  h1 {
    font-size: 90px;
  }
  h4 {
    font-size: 40px;
  }
}