@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600&display=swap);

body {
  margin: 0;
  background-color: #000000;
  text-align: center;
  position: relative;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  overflow-x: hidden; /* Hide scrollbars */

}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


h1 {
  color: white;
  font-size: 160px;
    font-weight: 600;
    margin: 0 auto;
    position: relative;
    letter-spacing: -0.05em;
    margin-bottom: 0px;
    max-width: 1100px;
    line-height: 0.9;
  }

h2 {
  color: white;
  font-size: 29px;
    font-weight: 500;
    margin: 0 auto;
    position: relative;
    margin-bottom: 30px;

}

h4 {
  color: rgb(114, 114, 114);
  font-size: 50px;
    font-weight: 600;
    margin: 0 auto;
    position: relative;
    margin-bottom: 60px;
    letter-spacing: -0.05em;
    line-height: 1;
    background-image: linear-gradient(90deg, #00FFA3, #3B7EDD, #DC1FFF);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;

}

h3 {
  letter-spacing: 10px;
  font-size: 25px;
  background-color: #f3ec78;
    background-image: linear-gradient(90deg, #00FFA3, #3B7EDD, #DC1FFF);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    display: inline-block;
}

p {
  font-size: 20px;
  opacity: 0.5;
  font-weight: 300;
  margin: 0 auto;
  width: 100%;
  max-width: 550px;
  margin-bottom: 50px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media only screen and (max-width: 991px) {
  h1 {
    font-size: 90px;
  }
  h4 {
    font-size: 40px;
  }
}
.App {
  text-align: center;
  overflow-x: hidden;
position: relative;
}

.bg {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
}

.App-logo {
  width: 100vw;
  z-index: 1;
  max-width: 1200px;
  margin-top: -900px;
  margin-bottom: 5vh;


}

.headerimg {
  width: 100%;
  max-width: 500px;
float: right;
position: relative;
left: 40px;
margin-bottom: -100px;
}

.listpod {
  box-sizing: border-box;
  padding: 20px;
  background-color: #222;
  border-radius: 20px;
  margin-top: 30px;
  margin-bottom: 80px;
}

.listpod a {
 color: #00FFA3;
 text-decoration: none;
}

.listpod p {
  color: #ccc;
}

.listpod a:hover {
  text-decoration: underline;
}

.listpod li {
  margin-bottom: 10px;
}

.redeem {
  text-align: left;
  margin-top: 20px;
  
}

.redeemtext {
  color: white;
}

.redeemtext p {
  max-width: none;
  margin-bottom: 20px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 200s linear;
            animation: App-logo-spin infinite 200s linear;
  }
}

.App-header {
  color: white;
  margin: 0 auto;
  z-index: 2;
  position: relative;
  text-align: center;
  box-sizing: border-box;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;



}

.glare-wrapper:hover {
  cursor: pointer;

}

.glare-wrapper {
  -webkit-mask-image: url(/static/media/1.dee11e5d.svg) !important;
          mask-image: url(/static/media/1.dee11e5d.svg) !important;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-size: contain;
          mask-size: contain;
}

#ii .glare-wrapper{
  -webkit-mask-image: url(/static/media/2.b95b48e7.svg) !important;
          mask-image: url(/static/media/2.b95b48e7.svg) !important;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-size: contain;
          mask-size: contain;
}
#iii .glare-wrapper{
  -webkit-mask-image: url(/static/media/3.8beb7ef0.svg) !important;
          mask-image: url(/static/media/3.8beb7ef0.svg) !important;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-size: contain;
          mask-size: contain;
}

#iv .glare-wrapper{
  -webkit-mask-image: url(/static/media/4.c5a21a85.svg) !important;
          mask-image: url(/static/media/4.c5a21a85.svg) !important;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-size: contain;
          mask-size: contain;
}
#v .glare-wrapper{
  -webkit-mask-image: url(/static/media/5.647fdfdb.svg) !important;
          mask-image: url(/static/media/5.647fdfdb.svg) !important;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-size: contain;
          mask-size: contain;
}
#vi .glare-wrapper{
  -webkit-mask-image: url(/static/media/6.0717f8d7.svg) !important;
          mask-image: url(/static/media/6.0717f8d7.svg) !important;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-size: contain;
          mask-size: contain;
}
#vii .glare-wrapper{
  -webkit-mask-image: url(/static/media/7.68d832b1.svg) !important;
          mask-image: url(/static/media/7.68d832b1.svg) !important;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-size: contain;
          mask-size: contain;
}
#viii .glare-wrapper{
  -webkit-mask-image: url(/static/media/8.ef201d47.svg) !important;
          mask-image: url(/static/media/8.ef201d47.svg) !important;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-size: contain;
          mask-size: contain;
}

#ix .glare-wrapper{
  -webkit-mask-image: url(/static/media/9.01b70730.svg) !important;
          mask-image: url(/static/media/9.01b70730.svg) !important;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-size: contain;
          mask-size: contain;
}
#x .glare-wrapper{
  -webkit-mask-image: url(/static/media/10.8576dfbd.svg) !important;
          mask-image: url(/static/media/10.8576dfbd.svg) !important;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-size: 100%;
          mask-size: 100%;
}
#xi .glare-wrapper{
  -webkit-mask-image: url(/static/media/11.27bd7c4a.svg) !important;
          mask-image: url(/static/media/11.27bd7c4a.svg) !important;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-size: contain;
          mask-size: contain;
}
#xii .glare-wrapper{
  -webkit-mask-image: url(/static/media/12.7cacf6ec.svg) !important;
          mask-image: url(/static/media/12.7cacf6ec.svg) !important;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-size: contain;
          mask-size: contain;
}

#x .glare {

background: linear-gradient(180deg, #E6533F 2.72%, #FFCF24 33.88%, #26BF9A 75.12%, #7D35F2 100%) !important;

}

.Zodiac-Text {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 700px;
}
.poweredby {
  margin-top: 20px;
  margin-bottom: 100px;
  width: 100%;
  max-width: 500px;

}


.flexgrid {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  max-width: 1140px;
  margin: 0 auto;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 200px;


}

.card:hover {
  cursor: pointer;
}

.card {
width: 100%;}

.flexgrid > div {
  box-sizing: border-box;
  padding: 20px;
  -webkit-flex-grow: 0.5;
          flex-grow: 0.5;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@media only screen and (max-width: 1200px) {
  .headerimg {
    margin-bottom: 0px;
    max-width: 400px;
    }

}

@media only screen and (max-width: 991px) {
  .App-logo {
    margin-top: -600px;

  }

  .headerimg {
    margin-bottom: 0px;
    max-width: 500px;
    }
}


@media only screen and (max-width: 768px) {

  .App-logo {
    margin-top: -450px;

  }

  .headerimg {
  margin-bottom: 0px;
  max-width: 400px;
  }
  

  .bg {
    display: none;
  }
}
